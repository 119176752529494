import React, { useEffect, useState } from "react";
import "./LandingPage.css";

function LandingPage() {
  const [items, setItems] = useState([
    "/Images/Bank-Update.svg",
    "/Images/final-update1.svg",
    "/Images/Invest-Update.svg",
    "/Images/final-update-2.svg",
    "/Images/Global-T.svg",
  ]);
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = width <= 480; // Adjust breakpoint as needed

  function circularMove(array) {
    if (array.length <= 1) {
      return array;
    }

    // Move the first item to the last position and then the second item to the first position
    const firstItem = array[0];
    const secondItem = array[1];
    const restItems = array.slice(2);

    return [secondItem, ...restItems, firstItem];
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setItems((prevItems) => circularMove(prevItems));
    }, 5000); // Change every 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <section className='Herosection'>
      <div className='CONT'>
        <h2 className='CONT-h1'>Affordable banking for you</h2>
        <p className='CONT-p'>
          Gain access to financial services through low monthly subscription. No
          fees, No interest, No limits- just total power over your money.
        </p>
        <div className='mobile-view'>
          <div className='CONT-carousel'>
            {items.map((item, index) => (
              <div key={index} className='carousel-item'>
                <img
                  src={item}
                  height={index === 1 ? "400" : "353px"}
                  style={{
                    objectFit: `${index === 1 ? "cover" : "contain"}`,
                    display: `${index > 2 ? "none" : "block"}`,
                    borderRadius: `${index === 1 ? "10px" : ""}`,
                    height: `${isMobile && index === 1 ? "290px" : ""}`,
                  }}
                  width='400px'
                  alt='carousel'
                  className='carousel-image'
                />
              </div>
            ))}
          </div>
        </div>
        {/* <div className="leftcont">
          <div className="boldtext">
            Get the credit you deserve <span className="now">now</span>
          </div>
          <p className="mediumtext">
            Access credit at an affordable rate, manage your credit{" "}
            <br className="break" /> limit and earn up to 20% cashback on repayment.
          </p>

          <div className="googleLink">
            <a href="https://play.google.com/store/apps/details?id=co.aibanc.Aibanc_APP" className="downldBtn">
              <img
                className="google"
                src="/Images/GooglePlayBadge2.png"
                alt=""
              />
            </a>
            <a href="https://apps.apple.com/app/aibanc/id6450451179" className="downldBtn">
              <img
                className="apple"
                src="/Images/appleStore.png"
                alt="appstore-download"
              />
            </a>
            <a href="/" tabIndex="0"><img className="bn46" src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"alt="bn45"/></a>
          </div>
        </div>
        <div className="rightcont">
          <img src="/Images/landing.png" alt="" />
        </div> */}
      </div>
    </section>
  );
}

export default LandingPage;
